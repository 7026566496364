import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React, { useRef, useState } from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Link from "../components/Link";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  pricingPageHeroContainer: {
    backgroundColor: yellow[100]
  },
  pricingPageTitle: {
    marginBottom: theme.spacing(4)
  },
  pricingPageFeesContainer: {
    background: theme.palette.grey[50]
  },
  pricingPageFeeTextField: {
    margin: theme.spacing(2, 0)
  },
  pricingPageFeeContainer: {
    padding: theme.spacing(4, 2),
    textAlign: "center"
  },
  pricingPageFeePoint: {
    margin: theme.spacing(4, 0)
  },
  pricingPageFeePointOwnedHeading: {
    margin: theme.spacing(-4, -2, 4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  pricingPageTermExplanation: {
    marginTop: theme.spacing(6)
  }
}));

const calculateFee = loanAmount => {
  if (loanAmount > 1000000) {
    return Math.round(loanAmount / 2 / 100);
  }

  return Math.round(loanAmount / 100);
};

const PricingPage = () => {
  const classes = useStyles();
  const description =
    "Here at commercial finance group we like to be open with the fees you'll be expected to pay.";
  const [isShowingFeeModal, setModalFee] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loanAmount, setLoanAmount] = useState("");
  const customFeeEl = useRef();

  const handleFeeButtonClick = () => {
    setModalFee(true);
  };

  const handleFeeModalClose = () => {
    setModalFee(false);
  };

  const handleFeeModalCancel = () => {
    setLoanAmount("");
    setModalFee(false);
  };

  const handleLoanRequiredChange = e => {
    setLoanAmount(e.currentTarget.value);
  };

  const handleCustomFeeElVisibility = () => {
    if (customFeeEl.current) {
      customFeeEl.current.scrollIntoView();
    }
  };

  return (
    <Layout>
      <SEO title="Pricing" description={description} />
      <Section className={classes.pricingPageHeroContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={10} md={8}>
              <Typography className={classes.pricingPageTitle} variant="h1">
                Flexible fees to fit your project size
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description} Business loan, asset finance, and invoice finance
                are not subject to a broker fee.
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={classes.heroButton}
            variant="contained"
            color="primary"
            giant
            onClick={handleFeeButtonClick}
          >
            Calculate Your Fees
          </Button>
        </Container>
      </Section>
      <Section className={classes.pricingPageFeesContainer}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper className={classes.pricingPageFeeContainer} elevation={0}>
                <Typography variant="h3" component="h2">
                  Up to £1 million
                </Typography>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    Upfront fee
                  </Typography>
                  <Typography variant="h6" component="div">
                    £0
                  </Typography>
                </div>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    Packaging fee
                  </Typography>
                  <Typography variant="h6" component="div">
                    £399
                  </Typography>
                </div>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    On completion
                  </Typography>
                  <Typography variant="h6" component="div">
                    1% of loan amount
                  </Typography>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={classes.pricingPageFeeContainer} elevation={0}>
                <Typography variant="h3" component="h2">
                  Over £1 million
                </Typography>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    Upfront fee
                  </Typography>
                  <Typography variant="h6" component="div">
                    £0
                  </Typography>
                </div>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    Packaging fee
                  </Typography>
                  <Typography variant="h6" component="div">
                    £399
                  </Typography>
                </div>
                <div className={classes.pricingPageFeePoint}>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="div"
                  >
                    On completion
                  </Typography>
                  <Typography variant="h6" component="div">
                    0.5% of loan amount
                  </Typography>
                </div>
              </Paper>
            </Grid>
            {loanAmount && (
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.pricingPageFeeContainer}
                  elevation={0}
                  ref={customFeeEl}
                >
                  <div className={classes.pricingPageFeePointOwnedHeading}>
                    <Typography variant="h3" component="h2">
                      Your Fees
                    </Typography>
                  </div>
                  <div className={classes.pricingPageFeePoint}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="div"
                    >
                      Upfront fee
                    </Typography>
                    <Typography variant="h6" component="div">
                      £0
                    </Typography>
                  </div>
                  <div className={classes.pricingPageFeePoint}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="div"
                    >
                      Packaging fee
                    </Typography>
                    <Typography variant="h6" component="div">
                      £399
                    </Typography>
                  </div>
                  <div className={classes.pricingPageFeePoint}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      component="div"
                    >
                      On completion
                    </Typography>
                    <Typography variant="h6" component="div">
                      £{calculateFee(loanAmount)}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid className={classes.pricingPageTermExplanation} item xs={12}>
              <Typography variant="body2">
                * Upfront fees relate to quoting and discussing your projects
                criteria.
              </Typography>
              <Typography variant="body2">
                * Packaging fees relate to packaging your projects criteria and
                presenting to all applicable lenders.
              </Typography>
              <Typography variant="body2">
                * On completion fees are required should you accept an offer of
                funding presented by us to you.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                For further details please see our{" "}
                <Link href="/terms-and-conditions/">terms and conditions</Link>{" "}
                and <Link href="/refund-policy/">refund policy</Link>.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Dialog
        open={isShowingFeeModal}
        fullScreen={fullScreen}
        onClose={handleFeeModalClose}
        onExited={handleCustomFeeElVisibility}
        maxWidth="xs"
        aria-labelledby="fee-calculator-dialog-title"
        aria-describedby="fee-calculator-dialog-description"
      >
        <DialogTitle id="fee-calculator-dialog-title">
          Fee Calculator
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="fee-calculator-dialog-description">
            Enter your required loan amount and we'll calculate your fees.
          </DialogContentText>
          <TextField
            id="pricing-page-loan-required"
            className={classes.pricingPageFeeTextField}
            label="Loan Amount"
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              )
            }}
            value={loanAmount}
            onChange={handleLoanRequiredChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFeeModalCancel} color="default">
            Clear
          </Button>
          <Button onClick={handleFeeModalClose} color="primary">
            Show Fees
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default PricingPage;
